import {onUpdated, onMounted} from 'vue';
import {useState} from '#imports';

const checkAppMode = () => {
	const isAppMode = useState('isAppMode', () => false);
	if (typeof window === 'undefined') {
		isAppMode.value = false;
		return isAppMode;
	}

	if (
		(window.navigator.standalone !== undefined &&
			window.navigator.standalone) ||
		window.matchMedia('(display-mode: standalone)').matches
	) {
		isAppMode.value = true;
		return isAppMode;
	}

	// Check for WebView mode (common user agent strings for iOS and Android WebView)
	const userAgent = navigator.userAgent || navigator.vendor || window.opera;

	const isIOSWebView =
		userAgent.match(/(isPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i) !== null;
	const isAndroidWebView =
		userAgent.match(/Version\/[\d.]+.*Chrome\/[.0-9]* Mobile/i) !== null;

	if (isIOSWebView || isAndroidWebView) {
		isAppMode.value = true;
		return isAppMode;
	}

	// Default to normal browser
	isAppMode.value = false;
	return isAppMode;
};

function useApp() {
	onMounted(() => checkAppMode());
	onUpdated(() => checkAppMode());

	if (window?.matchMedia) {
		window.matchMedia('(display-mode: standalone)').onchange = async () => {
			await checkAppMode();
		};
	}

	// Default to normal browser
	return {
		isApp: checkAppMode(),
	};
}

export {useApp};
